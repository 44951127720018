import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { saveState, loadState } from "./persistStore";

const middlewares = [thunk, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialData = loadState();

const store = createStore(
  rootReducer,
  initialData,
  //{},
  composeEnhancers(applyMiddleware(...middlewares))
);

store.subscribe(function () {
  //localStorage.removeItem("state");
  saveState(store.getState());
});

export { store };
