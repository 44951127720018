const initialState = {
  userRole: [],
  email: "",
  name: "",
  isSignedIn: false,
  loggedInWith: "",
  remember: false,
  fotoPerfil: "",
  uid: "",
  verificado: false,
};

export const login = (state = initialState, action) => {
  //alert(JSON.stringify(action, null, 1));
  //alert(JSON.stringify(state, null, 1));
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, ...action.payload };
    }
    case "CAMBIAR_FOTO_PERFIL_PROPIA": {
      return { ...state, ...action.payload };
    }
    case "LOGIN_WITH_FB": {
      return { ...state, ...action.payload };
    }
    // case "LOGIN_WITH_GOOGLE": {
    //   return { ...state, values: action.payload };
    // }
    // case "LOGIN_WITH_GITHUB": {
    //   return { ...state, values: action.payload };
    // }
    // case "LOGIN_WITH_JWT": {
    //   return { ...state, values: action.payload };
    // }
    case "CAMBIAR_NOMBRE_PROPIO": {
      return { ...state, ...action.payload };
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, ...initialState };
    }
    case "LOGOUT_WITH_FB": {
      return { ...state, ...initialState };
    }
    case "LOGOUT_WITH_FIREBASE_REMEMBER": {
      return { ...state, ...action.payload };
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};
