import * as firebase from "firebase/app";
import { history } from "../../../history";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import { config } from "../../../authServices/firebase/firebaseConfig";
import { Api } from "../../../components/api";

// Init firebase if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

let firebaseAuth = new firebase.auth();
let firebaseFirestore = new firebase.firestore();
let firebaseStorage = new firebase.storage();

export const firebaseWithAuth = () => {
  return firebaseAuth;
};

export const FirebaseStorage = () => {
  return firebaseStorage;
};

export const FirebaseFirestore = () => {
  return firebaseFirestore;
};

const obtenerDatosUsuarioFS = async (uid_auth) => {
  // console.log(uid_auth);
  let data;

  await firebaseFirestore
    .collection("usersapp")
    .where("uid_auth", "==", uid_auth)
    .get()
    .then((QuerySnapshop) => {
      QuerySnapshop.forEach((valores) => {
        //console.log(valores.data());
        data = valores.data();
      });

      return data;
    })
    .catch((error) => console.log(error));

  return data;
};

export const submitLoginWithFireBase = (
  email,
  password,
  remember,
  mostrarErrorLogin
) => {
  return (dispatch) => {
    //alert("Email a Loguear es: " + email);

    let userEmail = null,
      loggedIn = false;
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
          //Obtener Datos del Usuario en FireStore para casos de uso***********************************************************************************

          //alert(JSON.stringify(result));

          var dataUsuario = obtenerDatosUsuarioFS(user.uid);
          var userRole = [];
          var recordar = remember;
          var fotoPerfil = "";
          const veri = result.user.emailVerified;

          dataUsuario
            .then((data) => {
              // console.log(data);
              const { name, email, roles, url_foto_perfil } = data;

              // alert(
              //   "Nombre en AUTH: " +
              //     result.user.displayName +
              //     " Nombre en BD: " +
              //     name +
              //     "UID: " +
              //     user.uid
              // );

              if (result.user.displayName !== name) {
                result.user
                  .updateProfile({
                    displayName: name,
                  })
                  .catch((error) => console.log(error));
              }

              if (result.user.photoURL !== url_foto_perfil) {
                result.user
                  .updateProfile({
                    photoURL: url_foto_perfil,
                  })
                  .catch((error) => console.log(error));
              }

              userRole = roles;
              fotoPerfil = url_foto_perfil;

              if (user) {
                loggedIn = true;
                dispatch({
                  type: "LOGIN_WITH_EMAIL",
                  payload: {
                    userRole,
                    email: email,
                    name,
                    isSignedIn: loggedIn,
                    loggedInWith: "firebase",
                    remember: recordar,
                    fotoPerfil,
                    uid: user.uid,
                    verificado: veri,
                  },
                });
              }
              history.push("/Escritorio");
            })
            .catch((error) => console.log(error))
            .finally(() => {});
        });

        unsubscribe();
      })
      .catch((error) => {
        mostrarErrorLogin(error.code, error.message);
        console.log(error);
      });
  };
};

export const RestablecerContrasenia = (email, handlerError) => {
  const url = {
    url: "https://firebaseapp.lusitanatours.com/pages/forgot-password-success",
  };

  if (email !== undefined && email !== null) {
    firebaseAuth
      .sendPasswordResetEmail(email, url)
      .then(() => {
        handlerError(
          "restablecer/exitosamente",
          "Se ha enviado un email con las instrucciones para restablecer su contraseña. valide su correo.",
          true
        );
        console.log("Exitosa");
      })
      .catch((error) => {
        handlerError(error.code, error.message);
        console.log(error);
      });
  }
};

export const loginWithFB = (mostrarErrorLogin, remember) => {
  return (dispatch) => {
    let loggedIn = false;
    let provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "popup",
    });
    firebaseAuth
      .signInWithPopup(provider)
      .then((result) => {
        // console.log(
        //   "Usuario Facebook:" + JSON.stringify(result.user.uid, null, 1)
        // );

        firebase
          .firestore()
          .collection("usersapp")
          .doc(result.user.uid)
          //.where("uid_auth", "==", result.user.uid)
          .get()
          .then((user) => {
            //console.log("BD: " + user.data());

            if (user.exists) {
              var dataUsuario = obtenerDatosUsuarioFS(result.user.uid);
              var userRole = [];
              var recordar = remember;
              var fotoPerfil = "";
              const veri = result.user.emailVerified;

              dataUsuario
                .then((data) => {
                  // console.log(data);
                  const { name, email, roles, url_foto_perfil } = data;

                  if (result.user.displayName !== name) {
                    result.user
                      .updateProfile({
                        displayName: name,
                      })
                      .catch((error) => console.log(error));
                  }

                  if (result.user.photoURL !== url_foto_perfil) {
                    result.user
                      .updateProfile({
                        photoURL: url_foto_perfil,
                      })
                      .catch((error) => console.log(error));
                  }

                  userRole = roles;
                  //console.log("ROLES: " + roles);
                  fotoPerfil = url_foto_perfil;

                  if (user.exists) {
                    loggedIn = true;
                    dispatch({
                      type: "LOGIN_WITH_FB",
                      payload: {
                        userRole,
                        email: email,
                        name,
                        isSignedIn: loggedIn,
                        loggedInWith: "facebook",
                        remember: recordar,
                        fotoPerfil,
                        uid: result.user.uid,
                        verificado: veri,
                      },
                    });
                  }
                  history.push("/Escritorio");
                })
                .catch((error) => console.log(error))
                .finally(() => {});

              //console.log("Usuario SI Existe en la BD");
            } else {
              //console.log("Usuario NO Existe en la BD");
              mostrarErrorLogin("auth/user-not-found", "");
              Api("/DeleteUserAuth?uid=" + result.user.uid);
            }

            //return data;
          })
          .catch((error) => console.log("ERROR: " + error));

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        //let token = result.credential.accessToken,
        // The signed-in user info.
        // let user = result.user.email;
        // dispatch({
        //   type: "LOGIN_WITH_FB",
        //   payload: {
        //     user,
        //     //token,
        //     loggedInWith: "firebase",
        //   },
        // });
        // if (user) history.push("/Escritorio");
      })
      .catch((error) => {
        mostrarErrorLogin(error.code, error.message);
        console.log(error);
      });
  };
};

export const ActualizarFotoPerfilPropia = (
  uid_auth,
  filename,
  handleActualizarFPP
) => {
  return (dispatch) => {
    const updateFirestore = firebase.firestore().collection("usersapp");
    const updateAuth = firebase.auth().currentUser;

    firebase
      .storage()
      .ref("imageuser")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        //setFoto(url);
        updateFirestore
          .doc(uid_auth)
          .update({
            url_foto_perfil: url,
          })
          .then((exito) => {
            //console.log("Foto Actualizada en FireStore: " + exito);

            updateAuth
              .updateProfile({
                photoURL: url,
              })
              .then((res) => {
                dispatch({
                  type: "CAMBIAR_FOTO_PERFIL_PROPIA",
                  payload: {
                    //
                    fotoPerfil: url,
                  },
                });
                handleActualizarFPP(true, "");
              })
              .catch((error) => handleActualizarFPP(false, error));

            //**** */
          })
          .catch((error) => {
            handleActualizarFPP(false, error);
          });
      })
      .catch((error) => handleActualizarFPP(false, error));

    //console.log("Nombre Imagen Cargada Exitosamente: " + filename);
  };
};

export const ActualizarNombrePropio = (
  uid_auth,
  nombreCambiar,
  handleExito
) => {
  return (dispatch) => {
    //
    firebaseFirestore
      .collection("usersapp")
      .doc(uid_auth)
      .update({
        name: nombreCambiar,
      })
      .then((res) => {
        //dispatch
        firebaseAuth.currentUser
          .updateProfile({
            displayName: nombreCambiar,
          })
          .then((res) => {
            dispatch({
              type: "CAMBIAR_NOMBRE_PROPIO",
              payload: {
                name: nombreCambiar,
              },
            });
            handleExito(true);
            //dispatch
          });
      })
      .catch((error) => console.log(error));
  };
};

//CAMBIO DE PASSWORD DE USUARIO PROPIA
export const cambiarClavePropia = (
  antiguaClave,
  nuevaClave,
  handleErrorChangePassword,
  handleExitoChangePassword
) => {
  //

  const email = firebaseAuth.currentUser.email;
  const password = antiguaClave;

  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );

  firebaseAuth.currentUser
    .reauthenticateWithCredential(credential)
    .then((acep, rejc) => {
      firebaseAuth.currentUser
        .updatePassword(nuevaClave)
        .then(() => {
          //
          const json = {
            code: "OK",
            message:
              "La Contraseña se ha sido cambiado exitosamente. Usted debe volver a loguearse en Mi Cuenta Lusitana Tours...",
          };
          const jsonResult = JSON.stringify(json);
          handleExitoChangePassword(jsonResult);
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      const jsonError = JSON.stringify(error);
      handleErrorChangePassword(jsonError);
    });
};

export const VerificacionEmailCuenta = () => {
  var actionCodeSettings = {
    url: "https://firebaseapp.lusitanatours.com/EmailVerificationSuccess",
    handleCodeInApp: true,
  };
  firebase
    .auth()
    .currentUser.sendEmailVerification(actionCodeSettings)
    .then((ace, rej) => {
      // Verification email sent.
      console.log(ace, rej);
    })
    .catch((error) => console.log(error));
};

// export const loginWithTwitter = () => {
//   return (dispatch) => {
//     let provider = new firebase.auth.TwitterAuthProvider();
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function (result) {
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.user.displayName,
//           photoUrl = result.user.photoURL;
//         dispatch({
//           type: "LOGIN_WITH_TWITTER",
//           payload: {
//             user,
//             name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase",
//           },
//         });
//         history.push("/");
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };
// };

// export const loginWithGoogle = () => {
//   return (dispatch) => {
//     let provider = new firebase.auth.GoogleAuthProvider();
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function (result) {
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.user.displayName,
//           photoUrl = result.user.photoURL;
//         dispatch({
//           type: "LOGIN_WITH_GOOGLE",
//           payload: {
//             email: user,
//             name: name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase",
//           },
//         });
//         history.push("/");
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };
// };

// export const loginWithGithub = () => {
//   return (dispatch) => {
//     let provider = new firebase.auth.GithubAuthProvider();
//     firebaseAuth
//       .signInWithPopup(provider)
//       .then(function (result) {
//         let token = result.credential.accessToken,
//           user = result.user.email,
//           name = result.additionalUserInfo.username,
//           photoUrl = result.user.photoURL;

//         dispatch({
//           type: "LOGIN_WITH_GITHUB",
//           payload: {
//             user,
//             name,
//             photoUrl,
//             token,
//             loggedInWith: "firebase",
//           },
//         });
//         history.push("/");
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };
// };

// export const loginWithJWT = user => {
//   return dispatch => {
//     axios
//       .post("/api/authenticate/login/user", {
//         email: user.email,
//         password: user.password
//       })
//       .then(response => {
//         var loggedInUser

//         if (response.data) {
//           loggedInUser = response.data.user

//           dispatch({
//             type: "LOGIN_WITH_JWT",
//             payload: { loggedInUser, loggedInWith: "jwt" }
//           })

//           history.push("/")
//         }
//       })
//       .catch(err => console.log(err))
//   }
// }

// export const logoutWithJWT = () => {
//   return dispatch => {
//     dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
//     history.push("/pages/login")
//   }
// }

export const logoutWithFirebase = (remember, state) => {
  //remember = false;
  //alert("state en Logout es: " + JSON.stringify(state));
  //alert("RRRRR: " + remember);
  if (remember === true) {
    return (dispatch) => {
      //alert("Deslogueando y **NO** Borra LocalStorage");
      dispatch({ type: "LOGOUT_WITH_FIREBASE_REMEMBER", payload: state });
      //localStorage.removeItem("state");
      history.push("/");
    };
  } else {
    return (dispatch) => {
      //alert("Deslogueando y **SI** Borra LocalStorage");
      dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} });
      localStorage.removeItem("state");
      history.push("/");
    };
  }
};

export const changeRole = (role) => {
  return (dispatch) => dispatch({ type: "CHANGE_ROLE", userRole: role });
};
