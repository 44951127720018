import React from "react";
import { encode } from "base-64";

const API_URL_PROD =
  "https://us-central1-lusitana-viajes.cloudfunctions.net/app";

const API_URL_DEV = "http://localhost:5000/lusitana-viajes/us-central1/app";

const HTTP_AUTH_USER = process.env.REACT_APP_USER_API_FIREBASE_ADMIN;
const HTTP_AUTH_PASSWORD = process.env.REACT_APP_PASSWORD_API_FIREBASE_ADMIN;

const header = new Headers({
  Authorization: `Basic ${encode(`${HTTP_AUTH_USER}:${HTTP_AUTH_PASSWORD}`)}`,
});

const fetchGlobal = async (solicitud) => {
  return await fetch(API_URL_PROD + solicitud, { headers: header });
};

export const Api = async (solicitud) => {
  return (
    await fetchGlobal(solicitud).then((response) => {
      return response;
    })
  ).json();
};
